.aligncenter{
    /* text-align: center; */
    text-align: -webkit-center;
}
.why-container {
    padding: 0 2rem;

}
.service-box {
    padding: 0 1rem;
}

.service-intro {
    padding: 0 10rem 1rem;
}

.quote-col {
    padding: 0 2rem;
    margin: 1rem 7rem 0;
}

@media (min-width:1281px) {
    .why-container {
        padding-left: 5rem;
    }
}

@media (max-width: 992px) {
    .service-title {
        font-size: 24px !important;
        font-weight: bold;
    }
    .service-intro {
        font-size: 18px !important;
    }
    .why-title {
        font-size: 24px !important;
    }
    .why-info {
        font-size: 18px !important;
    }
    .quote {
        font-size: 20px !important;
    }
}
.aligncenter{
    /* text-align: center; */
    text-align: -webkit-center;
}


.b-container{
    padding:  20px 120px 0 120px !important;
}

.bs-container{
    padding:  0 120px 0 120px !important;
    margin: 50px 0 30px 0 !important;
}

.bt-container {
    padding:  0 130px 0 130px  !important;
}

.h-data {
    height: "50vh" !important;
}

@media (max-width: 1200px) {
    .bt-container {
        padding:  0 0px 0 0px !important;
    }
}
@media (max-width: 900px) {
    .b-container{
        padding:  0 0 0 0 !important;
    }
    .bs-container{
        padding:  0 0 0 0 !important;
    }
}
@media (max-width: 600px) {
    .bs-container{
        padding:  0 20px 0 20px !important;
        margin: 50px 0 30px 0 !important;
    }
    .h-data {
        height: "100vh" !important;
    }


    .rp-b {
        padding: 0 30px 0 50px !important;
    }

    .rp-m {
        padding: 0 50px 0 50px !important;
    }
}
@media (max-width: 500px) {

    .rp-m {
        padding: 0 30px 0 30px !important;
    }
    .rp-b {
        padding: 0 20px 0 20px !important;
    }

}
@media (max-width: 400px) {

    .rp-m {
        padding: 0 10px 0 10px !important;
    }
    .rp-b {
        padding: 0 0 0 0 !important;
    }

}
.aligncenter{
    /* text-align: center; */
    text-align: -webkit-center;
}
.sub-content-dashboard {
    padding:0 30px 0 30px;
}
.sub-ticket-content-left {
    padding-right: 5px;
}
.sub-ticket-content-rigth {
    padding-left: 5px;
}
.date-show {
    margin-bottom: -30px;
}

.content-select {
    margin: -37px 0 0 0;
}
.content-log {
    margin-top: 50px !important; 
    padding-left: 50px !important; 
}
.box-right {
    margin-right: 15px;
}
.box-left {
    margin-left: 15px;
}

.box-sum {
    padding: 10px 45px 10px 45px !important; 
}

.table-content {
    padding-left: 30px !important; 
}

.search-text {
    margin-top: -25px !important; 
}
@media (max-width: 1200px) {
    .content-log {
        padding-left: 20px !important; 
    }
    .box-sum {
        padding: 10px 30px 10px 30px !important; 
    }
    .search-text {
        margin-top: 10px !important; 
        margin-bottom: -7px !important; 
    }
}
@media (max-width: 900px) {
    .search-text {
        margin-top: -25px !important; 
        margin-bottom: 0 !important; 
    }
    .table-content {
        padding-left: 0 !important; 
    }
    .date-select {
        margin-top: 10px;
    }
    .content-select {
        margin: -40px 0 0 0;
    }
    .content-log {
        padding-left: 0px !important; 
    }
    .box-tickets {
        margin-bottom: 10px;
    }
    .sub-ticket-content-left {
        padding-right: 0;
    }
    .sub-ticket-content-rigth {
        padding-left: 0;
    }
}
@media (max-width: 600px) {
    .sub-content-dashboard {
        padding:0 10px 0 10px;
    }

    .box-right {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .box-left {
        margin-left: 0;
    }
    .search-text {
        margin-top: 10px !important; 
        margin-bottom: -7px !important; 
    }
}
@media (max-width: 425px) {
    .date-show {
        margin-bottom: 20px;
    }
    .box-sum {
        padding: 10px 10px 10px 10px !important; 
    }
}


.aligncenter{
    /* text-align: center; */
    text-align: -webkit-center;
}

.ag-box {
    padding: 0 1rem;
}

/* .form-box {
    overflow: auto;
} */

.form-list {
    white-space: initial;
}

@media (max-width: 992px) {
    .ag-icon {
        width: 100% !important;
        /* height: 80px !important; */
        /* padding-left: 1rem !important; */
        padding-right: 1rem !important;
    }
}
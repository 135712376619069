.hop-container-en {
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}
.hop-container-th {
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
  
 
}

.hop-container-th-heading {
    padding-left: 100px !important;
    padding-right: 100px !important;
}

.hop-container-th-top {
    position: relative;
    width: 100%; /* กำหนดความกว้าง */
    height: auto; /* กำหนดความสูง */
    background-image: url("../../../components/layouts/assets/hop-th-1.png"); /* ตั้งค่าภาพพื้นหลัง */
    background-position: center; /* จัดตำแหน่งภาพให้อยู่ตรงกลาง */
    background-repeat: no-repeat; /* ไม่ให้ภาพซ้ำ */
    background-color: black;
    background-size: cover;
    padding-top: 20px;
    padding-bottom: 20px;
}
.hop-container-th-top::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* เลเยอร์สีดำโปร่งใส */
    z-index: 1; /* ทำให้เลเยอร์นี้อยู่เหนือพื้นหลัง */
}

.hop-container-th-top * {
    position: relative;
    z-index: 2; /* ทำให้เนื้อหาทั้งหมดอยู่เหนือเลเยอร์โปร่งใส */
    color: #ffffff; /* ทำให้ข้อความเป็นสีขาวเพื่อให้ชัดเจน */
}
.hop-container-th-top-en {
    position: relative;
    width: 100%; /* กำหนดความกว้าง */
    height: auto; /* กำหนดความสูง */
    background-image: url("../../../components/layouts/assets/hop-en.png"); /* ตั้งค่าภาพพื้นหลัง */
    background-position: center; /* จัดตำแหน่งภาพให้อยู่ตรงกลาง */
    background-repeat: no-repeat; /* ไม่ให้ภาพซ้ำ */
    background-color: #DDCFBB;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 20px;
}
.hop-container-th-top-en::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* เลเยอร์สีดำโปร่งใส */
    z-index: 1; /* ทำให้เลเยอร์นี้อยู่เหนือพื้นหลัง */
}

.hop-container-th-top-en * {
    position: relative;
    z-index: 2; /* ทำให้เนื้อหาทั้งหมดอยู่เหนือเลเยอร์โปร่งใส */
    color: #DDCFBB; /* ทำให้ข้อความเป็นสีขาวเพื่อให้ชัดเจน */
}

.hop-container-th-top-back {
    background-color: #DDCFBB !important;
}
.hop-container-th-top-back-bottom {
    background-color: #F9F6F0 !important;
}

.hop-container-th-top-back-bottom-cd {
    position: relative;
    width: 100%; /* กำหนดความกว้าง */
    height: auto; /* กำหนดความสูง */
    background-image: url("../../../components/layouts/assets/back-hop.png"); /* ตั้งค่าภาพพื้นหลัง */
    background-position: center; /* จัดตำแหน่งภาพให้อยู่ตรงกลาง */
    background-repeat: no-repeat; /* ไม่ให้ภาพซ้ำ */
    background-color: #DDCFBB;
    background-size: cover;
    padding-bottom: 20px;
}
.hop-container-th-top-back-bottom-cd::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(249, 246, 240, 0.8); /* เลเยอร์สีดำโปร่งใส */
    z-index: 1; /* ทำให้เลเยอร์นี้อยู่เหนือพื้นหลัง */
}

.hop-container-th-top-back-bottom-cd * {
    position: relative;
    z-index: 2; /* ทำให้เนื้อหาทั้งหมดอยู่เหนือเลเยอร์โปร่งใส */
    color: #DDCFBB; /* ทำให้ข้อความเป็นสีขาวเพื่อให้ชัดเจน */
}

.hop-container-th-top-en {
    position: relative;
    width: 100%; /* กำหนดความกว้าง */
    height: auto; /* กำหนดความสูง */
    background-image: url("../../../components/layouts/assets/hop-en.png"); /* ตั้งค่าภาพพื้นหลัง */
    background-position: center; /* จัดตำแหน่งภาพให้อยู่ตรงกลาง */
    background-repeat: no-repeat; /* ไม่ให้ภาพซ้ำ */
    background-color: #DDCFBB;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 20px;
}
.hop-container-th-top-en::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* เลเยอร์สีดำโปร่งใส */
    z-index: 1; /* ทำให้เลเยอร์นี้อยู่เหนือพื้นหลัง */
}

.hop-text-heading {
    font-family: Arial !important;
    margin-bottom: 1.5rem !important;
    margin-top: 4rem !important;
    text-align: start !important;
    font-weight: 600 !important;
    font-size: 38px !important;
    line-height: 1.2 !important;
    color: #484237 !important;
}
.hop-text-heading-en {
    font-family: Arial !important;
    margin-bottom: 1.5rem !important;
    margin-top: 4rem !important;
    text-align: start !important;
    font-weight: 600 !important;
    font-size: 35px !important;
    line-height: 1.2 !important;
    color: #484237 !important;
}
.hop-text-heading-back-en {
    font-family: Arial !important;
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
    text-align: start !important;
    font-weight: 600 !important;
    font-size: 60px !important;
    line-height: 1.2 !important;
    color: whitesmoke !important;
}
.hop-text-heading-back {
    font-family: Arial !important;
    margin-bottom: 1.5rem !important;
    margin-top: 4rem !important;
    text-align: start !important;
    font-weight: 600 !important;
    font-size: 45px !important;
    line-height: 1.2 !important;
    color: white !important;
}

.hop-text-descritons {
    font-family: Arial !important;
    margin-bottom: 1.2rem !important;
    text-align: start ;
    font-weight: 500 !important;
    font-size: 17px !important;
    color: #B2A599 !important;
}
.hop-text-descritons-back {
    font-family: Arial !important;
    margin-bottom: 1.2rem !important;
    text-align: start !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    color: white !important;
}
.hop-text-descritons-back-eng {
    font-family: Arial !important;
    margin-bottom: 1.2rem !important;
    text-align: start !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    color: #DDCFBB !important;
}

.hop-home{
    padding-left: 60px !important;
    padding-right: 60px !important;
    padding-bottom: 30px;
    margin-bottom: 50px;
    background-color: #F9F6F0 !important;
}

.hop-home-sub {
    margin-bottom: 20px;
    background-color: #F2EBDF !important;
    flex: 1 !important;
}

.hop-text-sub-heading {
    font-family: Arial !important;
    color: #484237 !important;
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
    text-align: start;
    font-weight: 600 !important;
    font-size: 25px !important;
    
}
.col-hop {
    padding-right: 30px !important;
    padding-bottom: 0px;
}

.hop-text-sub-heading-low {
    font-family: Arial !important;
    color: #B2A599 !important;
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
    text-align: start;
    font-weight: 600 !important;
    font-size: 22px !important;
    
}
.content-contact-show{
    padding-left: 20px;
}

.content-contact-col {
    display: flex;
    align-items: center; /* จัดสามเหลี่ยมและข้อความให้อยู่ในแนวเดียวกันในแนวตั้ง */
}
.content-contact-col-show {
    display: flex;
    align-items: center; /* จัดสามเหลี่ยมและข้อความให้อยู่ในแนวเดียวกันในแนวตั้ง */
}

.content-contact-col::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 7px solid transparent; /* ความกว้างของขอบด้านซ้าย */
    border-right: 7px solid transparent; /* ความกว้างของขอบด้านขวา */
    border-top: 13px solid #B2A599; /* สีและขนาดของขอบด้านบน */
    margin-left: -18px;
    margin-right: 10px; /* ระยะห่างระหว่างสามเหลี่ยมกับเนื้อหา */
    transform: rotate(-90deg); 
}
.content-contact-col-show::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 7px solid transparent; /* ความกว้างของขอบด้านซ้าย */
    border-right: 7px solid transparent; /* ความกว้างของขอบด้านขวา */
    border-top: 13px solid #B2A599; /* สีและขนาดของขอบด้านบน */
    margin-left: -18px;
    margin-right: 10px; /* ระยะห่างระหว่างสามเหลี่ยมกับเนื้อหา */
}

@media (max-width: 1200px) {
    .hop-container-th {
        padding-left: 0 !important;
        padding-right: 0  !important;
        margin-top: 50px !important;
    }
    
    .hop-container-th-heading {
        padding-left: 0 !important;
        padding-right: 0  !important;
    }
}

@media (max-width: 900px) {
    .hop-text-heading-back-en {
        font-size: 55px !important;
    }
}
@media (max-width: 620px) {
    .hop-home{
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .hop-text-heading-back-en {
        font-size: 40px !important;
    }
    .hop-text-heading {
        font-size: 30px !important;
    }
}
@media (max-width: 472px) {

    .hop-text-heading-back-en {
        font-size: 35px !important;
    }

    .hop-text-sub-heading {
        font-size: 20px !important;
    }
    .hop-text-sub-heading-low {
        font-size: 20px !important;
    }
}
@media (max-width: 400px) {

    .hop-text-heading-back-en {
        font-size: 30px !important;
    }

    .hop-text-heading {
        font-size: 30px !important;
    }
}
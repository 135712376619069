.notfound-container {
    margin-top: 100px;
    margin-bottom: 100px;
}

.notfound-text-heading {
    font-size: 50px !important;
}

.notfound-text-descritons {
    font-size: 25px !important;
}

@media (max-width: 600px) {
    
    .notfound-text-heading {
        font-size: 40px !important;
    }
    
    .notfound-text-descritons {
        font-size: 20px !important;
    }
}
@media (max-width: 350px) {
    
    .notfound-text-heading {
        font-size: 30px !important;
    }
    
    .notfound-text-descritons {
        font-size: 20px !important;
    }
}
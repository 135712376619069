.about-container {
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}

.why-container {

    margin-bottom: 50px !important;
}

.aligncenter{
    /* text-align: center; */
    text-align: -webkit-center;
}

.link-url {
    color: #E8630A !important;
}
.container-text {
    padding-right: 50px !important;
}
.container-img {
    display: flex;
    justify-content: flex-end; /* จัดให้รูปชิดขวา */
    align-items: center; /* จัดแนวกลางแนวตั้ง */
}
.img-about {
    width: 100%;
    border-radius: 2%;
}
.img-sub {
    width: 100%;
    border-radius: 2%;
}
.about-text-heading {
    font-family: Arial !important;
    color:#4F4A41 !important;
    margin-bottom: 1.5rem !important;
    margin-top: 4rem !important;
    text-align: start !important;
    font-weight: 600 !important;
    font-size: 50px !important;
    line-height: 1.2 !important;
}
.about-text-heading-title {
    font-family: Arial !important;
    color:#4F4A41 !important;
    margin-bottom: 1.5rem !important;
    margin-top: 4rem !important;
    text-align: start !important;
    font-weight: 600 !important;
    font-size: 60px !important;
    line-height: 1.2 !important;
}
.about-text-sub-heading {
    font-family: Arial !important;
    color:#4F4A41 !important;
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
    text-align: start !important;
    font-weight: 600 !important;
    font-size: 25px !important;
    
}
.about-text-descritons {
    font-family: Arial !important;
    color:#4F4A41 !important;
    margin-bottom: 1.2rem !important;
    text-align: start !important;
    font-weight: 500 !important;
    font-size: 18px !important;
}

.col-grap {
    padding-right: 30px !important;
}

.service-box {
    padding: 0 1rem;
}

.service-intro {
    padding: 0 10rem 1rem;
}

.quote-col {
    padding: 0 2rem;
    margin: 1rem 7rem 0;
}

@media (max-width: 1200px) {
    .about-container {
        padding-left: 0 !important;
        padding-right: 0  !important;
        margin-top: 50px !important;
    }
    .container-text {
        padding-right: 20px !important;
    }
}
@media (max-width: 900px) {
    .about-container {
        margin-top: 50px !important;
    }
    .container-text {
        padding-right: 0 !important;
    }
}




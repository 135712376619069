body {font-family: Arial, Helvetica, sans-serif;}
* {box-sizing: border-box;}

  .form-inline {  
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .form-inline label {
    margin: 5px 10px 5px 0;
  }

  .form-inline input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
  }

  .share-input {
    height: 100%;
  }

  .button-section {
    margin: 3%;
  }
  .button {
    padding: 7px 10px;
    background-color: #254E58;
    border: 1px solid #ddd;
    color: white;
    cursor: pointer;
    border-radius: 10%;
  }

  .button:hover {
    background-color: #254E58;
  }

  .remove {
    background-color: rgb(192, 53, 53);
  }
  .remove:hover {
    background-color: rgb(187, 43, 43);
  }
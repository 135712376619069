.aligncenter{
    /* text-align: center; */
    text-align: -webkit-center;
}
.card-home{
    padding-left: 60px !important;
    padding-right: 60px !important;
    padding-bottom: 50px;
    margin-left: -80px !important;
    margin-right: -80px !important;

}

.card-home-sub {
    margin-bottom: 20px;
    background-color: #ffebcc !important;
    flex: 1 !important;
}

.col-grap-home {
    padding-right: 30px !important;
    padding-bottom: 40px;
}

.about-text-heading-home {
    font-family: Arial !important;
    color:#4F4A41 !important;
    margin-bottom: 1.5rem !important;
    margin-top: 4rem !important;
    text-align: start !important;
    font-weight: 600 !important;
    font-size: 50px !important;
    line-height: 1.2 !important;
}

.about-text-sub-home {
    padding-top: 5px;
    font-family: Arial !important;
    color:#4F4A41 !important;
    text-align: start !important;
    font-weight: 600 !important;
    font-size: 25px !important;
    
}
@media (max-width: 1100px) {

    .card-home{
        padding-left: 20px !important;
        padding-right: 20px !important;
        padding-bottom: 50px !important;

        margin-left: 0 !important;
        margin-right: 0 !important;
    
    }

}
@media (max-width: 900px) {
    .col-grap-home {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    /* .card-home{
        padding-left: 20px !important;
        padding-right: 20px !important;
        padding-bottom: 50px !important;

        margin-left: 20px !important;
        margin-right: 20px !important;
    
    } */
}

@media (max-width: 500px) {
    .card-home{
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 50px !important;

        margin-left: 10px !important;
        margin-right: 10px !important;
    
    }
    .about-text-heading-home  {
        font-size: 40px !important;
    }
}
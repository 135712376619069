.part-heading {
    margin-left: 2rem !important;
    margin-bottom: 0.5rem !important;
}

@media (min-width:1281px) {
    .part-heading {
        margin-bottom: 0 !important;
    }
}

@media (max-width:480px) {
    .requestButton {
        width: 75% !important;
        margin-bottom: 1rem !important;
    }
}
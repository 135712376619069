.quiz-container {
    padding: 70px 100px 70px 100px ;
    height: 100% !important; ;
    background-color: #DDCFBB !important; 
}

.quiz-content {
    margin-bottom: 20px;
}

.quiz-box {
    padding: 30px 50px 20px 50px;
    text-align: center;
    border-radius: 20px !important; 
}


.heading-text-quiz {
    font-size: 28px !important;
    font-weight: 600 !important;
}

.content-text-quiz {
    font-size: 22px !important;
    font-weight: 600 !important;
}
.content-sub-text-quiz {
    font-size: 20px !important;
    font-weight: 500 !important;
}

.quiz-stapper{
    margin: auto 100px auto 100px !important;
}

.quiz-choice {
    margin: 10px 100px 0 100px !important;
}

.quiz-box-sumary {
    margin: 0 50px 0 50px;
    padding: 20px 0 20px 50px;
}

@media (max-width: 1100px) {
    .quiz-stapper{
        margin: auto 50px auto 50px !important;
    }
    .quiz-choice {
        margin: 10px 20px 0 20px !important;
    }

    .quiz-box-sumary {
        margin: 0 0 0 0  !important;
        padding: 20px 0 20px 20px;
    }
    
}
@media (max-width: 900px) {
    .quiz-stapper{
        margin: auto 0 auto 0 !important;
    }

    .quiz-box {
        padding: 30px 50px 20px 50px !important; 
    }

    .quiz-container {
        padding: 70px 50px 70px 50px !important;
    }

    .quiz-sumary-detail{
        margin-bottom: 10px !important;
    }
}

@media (max-width: 650px) {

    .quiz-box {
        padding: 30px 10px 20px 10px !important; 
    }

    .quiz-choice {
        margin: 10px 10px 0 10px !important;
    }

    .quiz-container {
        padding: 70px 10px 70px 10px !important;
    }

    .heading-text-quiz {
        font-size: 23px !important;
    }
    
    .content-text-quiz {
        font-size: 18px !important;
    }
    .content-sub-text-quiz {
        font-size: 16px !important;
    }
}
@media (max-width: 450px) {

    .quiz-box {
        padding: 30px 0 20px 0 !important; 
    }

    .quiz-choice {
        margin: 10px 0 0 0 !important;
    }

    .quiz-container {
        padding: 70px 0 70px 0 !important;
    }

    .heading-text-quiz {
        font-size: 20PX !important;
    }
    
    .content-text-quiz {
        font-size: 16px !important;
    }
    .content-sub-text-quiz {
        font-size: 16px !important;
    }

    
}
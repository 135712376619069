.box-res {
    padding-right: 20px;
    margin-bottom: 10px;
}
.box-res-buttom {
    margin-bottom: 10px;
}
.back-bottom {
    margin: 50px 0 0 -150px;
}

@media (max-width: 1350px) {
    .back-bottom {
        margin: 50px 0 0 -60px !important;
    }
}
@media (max-width: 1200px) {
    .back-bottom {
        margin: 50px 0 0 -100px !important;
    }
}
@media (max-width: 1000px) {
    .back-bottom {
        margin: 50px 0 0 -50px !important;
    }
}
@media (max-width: 900px) {
    .back-bottom {
        margin: 50px 0 0 -80px !important;
    }
}
@media (max-width: 700px) {
    .back-bottom {
        margin: 50px 0 0 -50px !important;
    }
}

@media (max-width: 600px) {
    .box-res {
        padding-right: 0;
    }
    .back-bottom {
        margin: 20px 0 50px 0px !important;
    }
}
.aligncenter{
    /* text-align: center; */
    text-align: -webkit-center;
}
.col {
    flex: 1 0;
}
.contact-col {
    margin: auto;
    width: 100%;
}
.contact-heading {
    color: #3d5a80;
    margin-bottom: 2rem;
    text-align: left;
}
.contact-details {
    color: #98c1d9;
    margin-bottom: 1rem;
}


.info-container {
    padding: 1rem 2rem;
}

.input-label {
    margin: auto !important;
}

.input-field {
    margin: 0.7rem 0 !important;
}

.datasheet-title {
    padding: 1rem 0 0 0.5rem;
}

.datasheet-container {
    margin: 2rem;
}
.aligncenter{
    /* text-align: center; */
    text-align: -webkit-center;
}
body {
  background: rgb(204, 204, 204);
}

.page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}
.page[size="A4"][layout="landscape"] {
  width: 29.7cm;
  height: 21cm;
}
.page[size="A3"] {
  width: 29.7cm;
  height: 42cm;
}
.page[size="A3"][layout="landscape"] {
  width: 42cm;
  height: 29.7cm;
}
.page[size="A5"] {
  width: 14.8cm;
  height: 21cm;
}
.page[size="A5"][layout="landscape"] {
  width: 21cm;
  height: 14.8cm;
}

@page {
  size: 7in 9.25in;
  margin: 27mm 16mm 27mm 16mm;
}
.page {
  position: relative;
  background: white;
  display: block;
  margin: 0 auto;
  /* margin-bottom: 0.5cm; */
  border: 1px solid;
  /* box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5); */
}
.page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}
.page[size="A4"][layout="portrait"] {
  width: 29.7cm;
  height: 21cm;
}
@media print {
  body,
  page {
    margin: 0;
    box-shadow: 0;
  }
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
}

.page {
  display: inline-block;
  position: relative;
  height: 297mm;
  width: 210mm;
  font-size: 12pt;
  /* margin: 2em auto; */
  padding: calc(var(--bleeding) + var(--margin));
  /* box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5); */
  background: white;
}

@media screen {
  .page::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: calc(100% - var(--bleeding) * 2);
    height: calc(100% - var(--bleeding) * 2);
    margin: var(--bleeding);
    /* outline: thin dashed black; */
    pointer-events: none;
    z-index: 9999;
  }
}

.v-btn:not(.v-btn--round).v-size--default.buttonblue {
  background-color: #1d2442;
  color: white;
}
.btnuploadfile {
  border: 2px solid;
}
.v-btn:not(.v-btn--round).v-size--default.buttonred {
  width: 100%;
  background-color: #9e3b1b;
  color: white;
}
.v-btn:not(.v-btn--round).v-size--default.buttonwhite {
  width: 100%;
  background-color: #f5f5f5;
  color: black;
}

h3 {
  font-size: 1.17em;
}
h4 {
  font-size: 1em;
}

.test {
  border-style: double;
}

.borderblack {
  border-style: solid;
  border-width: thin;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.btn-light {
  color: #333;
  background-color: #fafafa;
  border-color: #ddd;
}

@media (min-width: 768px) {
  .wmin-md-400 {
    min-width: 400px !important;
  }
}

.btn-primary {
  color: #fff;
  background-color: #2196f3;
}

.btn-labeled > b {
  position: absolute;
  top: -1px;
  background-color: blue;
  display: block;
  line-height: 1;
  padding: 0.62503rem;
}
.alignleft {
  float: left;
}
.alignright {
  float: right;
}
.aligncenter {
  text-align: center;
}
.captionreport {
  font-size: 0.85rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  line-height: 1.25rem;
  font-family: "Roboto", sans-serif;
}
.caption {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  line-height: 1.25rem;
  font-family: "Roboto", sans-serif;
}
.captionhtable {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  line-height: 1.25rem;
  font-family: "Roboto", sans-serif;
}
.rowprpo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-right: -12px;
  margin-left: -12px;
}
.colprpo {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.col-md-1report {
  word-wrap: break-word;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333333333333%;
  flex: 0 0 8.333333333333333%;
  max-width: 8.333333333333333%;
}
.col-md-2report {
  word-wrap: break-word;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66666666666667%;
  flex: 0 0 16.66666666666667%;
  max-width: 16.66666666666667%;
}

.col-md-3report {
  word-wrap: break-word;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-md-4report {
  word-wrap: break-word;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333333333333%;
  flex: 0 0 33.33333333333333%;
  max-width: 33.33333333333333%;
}

.col-md-5report {
  word-wrap: break-word;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66666666666667%;
  flex: 0 0 41.66666666666667%;
  max-width: 41.66666666666667%;
}

.col-md-6report {
  word-wrap: break-word;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-md-7report {
  word-wrap: break-word;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333333333333%;
  flex: 0 0 58.33333333333333%;
  max-width: 58.33333333333333%;
}
.col-md-8report {
  word-wrap: break-word;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66666666666667%;
  flex: 0 0 66.66666666666667%;
  max-width: 66.66666666666667%;
}
.col-md-9report {
  word-wrap: break-word;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-md-10report {
  word-wrap: break-word;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333333333333%;
  flex: 0 0 83.33333333333333%;
  max-width: 83.33333333333333%;
}
.col-md-11report {
  word-wrap: break-word;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66666666666667%;
  flex: 0 0 91.66666666666667%;
  max-width: 91.66666666666667%;
}
.col-md-12report {
  word-wrap: break-word;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.mt-30prpo {
  margin-top: -30px !important;
}

.mt30 {
  margin-top: 30px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt25 {
  margin-top: 25px !important;
}

.mt-25prpo {
  margin-top: -25px !important;
}

.mr30prpo {
  margin-right: 30px !important;
}

.ml30prpo {
  margin-left: 30px !important;
}

.ml2prpo {
  margin-left: 2px !important;
}

.ml10prpo {
  margin-left: 10px !important;
}

.borderblockpr {
  border: 2px solid black;
  width: 100px;
  height: 150px;
  background: white;
  box-sizing: border-box;
  display: inline-block;
}

.col-md-4pr {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
}
.col-md-8pr {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;
}
.col-md-4prrd {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-md-8prrd {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.textalignleft {
  text-align: left;
}
.textalignleftcus {
  text-align: left;
}
.textalignright {
  text-align: right;
}
table {
  /* table-layout: fixed; */
  border-collapse: collapse;
  width: 100%;
  /* border: 1px solid black; */
}
.prbordertop {
  border-top: 1px solid black;
}
.prborderbottom {
  border-bottom: 1px solid black;
}
.prbordertop2pxhtable {
  padding-top: 8px;
  border-top: 2px solid black;
}
.prborderbottom2pxhtable {
  padding-bottom: 8px;
  border-bottom: 2px solid black;
}

td {
  /* border: 1px solid black; */
}
.width15 {
  word-wrap: break-word;
  width: 15%;
}
.width10 {
  word-wrap: break-word;
  width: 10%;
}
.width18 {
  word-wrap: break-word;
  width: 18%;
}
.width20 {
  word-wrap: break-word;
  width: 20%;
}
.width25 {
  word-wrap: break-word;
  width: 22%;
}
.width30 {
  word-wrap: break-word;
  width: 30%;
}
.width35 {
  word-wrap: break-word;
  width: 35%;
}
.width40 {
  word-wrap: break-word;
  width: 40%;
}
.width45 {
  word-wrap: break-word;
  width: 45%;
}
.width50 {
  word-wrap: break-word;
  width: 50%;
}
.width60 {
  word-wrap: break-word;
  width: 60%;
}
.width70 {
  word-wrap: break-word;
  width: 70%;
}
.width90 {
  word-wrap: break-word;
  width: 90%;
}

.width100 {
  word-wrap: break-word;
  width: 100%;
}
@media print {
  /*
                body.A3.landscape { width: 420mm }
                body.A3, body.A4.landscape { width: 297mm }
                body.A4, body.A5.landscape { width: 210mm }
                body.A5 { width: 148mm }*/
  .printonly {
    display: block;
  }
  .noprint {
    display: none;
  }
  .A3.landscape {
    width: 420mm;
  }
  .A3,
  .A4.landscape {
    width: 297mm;
  }
  .A4,
  .A5.landscape {
    width: 210mm;
  }
  .A5 {
    width: 148mm;
  }
}
header {
  top: 0;
  padding-top: 150mm;
  padding-bottom: 3mm;
}
footer {
  bottom: 0;
  color: #000;
  padding-top: 3mm;
  padding-bottom: 5mm;
}
header,
footer {
  /* position: fixed; */
  left: 0;
  right: 0;
  background-color: #ccc;
  padding-right: 1.5cm;
  padding-left: 1.5cm;
}

.footerprpo {
  position: absolute;
  bottom: 10px;
  width: 90%;
}
.borderauto {
  border: 1px solid;
  margin: -1px;
}
.bordersignature {
  height: 110px;
  border: 1px solid;
  margin: -1px;
}
.fixtworow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
  overflow: hidden;
  text-align: left;
}
.fixonerow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 20px;
  overflow: hidden;
  text-align: left;
}
.fontsize10 {
  font-size: 10px;
}

.GridWrapper {
    width: 50% !important;
    margin: auto !important;
    margin-bottom: 1rem !important;
}

.TotalCardWrapper {
    border-color: #CE9461 !important;
    border-radius: 1rem !important;
    color: #73777B !important;
}

.TotalCardContent {
    text-align: center !important;
    background-color: #FDEEDC !important;
}

.ProcessCardWrapper {
    border-color: #FF8B13 !important;
    border-radius: 1rem !important;
    color: #73777B !important;
}

.ProcessCardContent {
    text-align: center !important;
    background-color: #FFF4CF !important;
}

.FinishCardWrapper {
    border-color: #73777B !important;
    border-radius: 1rem !important;
    color: #73777B !important;
    min-width: 32% !important;
}

.FinishCardContent {
    text-align: center !important;
    background-color: #D6E4E5 !important;
}
.faq-container {
    margin: 100px 0 0 0;
}
.custom-link {
    text-decoration: none !important;
}
.faq-back-container {
    text-align: left;
    padding: 0 0 0 0;
}

.sub-question-description-container {
   padding-right: 50px;
   padding-bottom: 10px;
}

.faq-search-container{
    padding: 20px 0 40px 0;
}

.faq-sub-container {
    padding: 30px 0 30px 0;
    cursor: pointer;
    
}

.faq-bullet-container {
    padding-left: 15px !important;
    padding-bottom: 10px !important;

}

.faq-sub-container-icon
{
    padding-right: 50px;
}

.faq-sub-sub-container {
    padding: 20px 0 20px 0;
    cursor: pointer;
}

.heading-text-faq {
    font-size: 40px !important;
    font-weight: 500 !important;
}

.sub-heading-text-faq {
    font-size: 25px !important;
    font-weight: 600 !important;
    color: #d18250;
    transition: color 0.3s ease; 
}

.sub-heading-text-faq:hover {
    color: burlywood; /* เปลี่ยนสีเมื่อ hover */
}

.content-text-faq{
    font-size: 21px !important;
}

.content-text-bullet-faq{
    font-size: 21px !important;
    line-height: 1.1 !important; 
}

.sub-heading-text-faq:hover {
    color: burlywood; /* เปลี่ยนสีเมื่อ hover */
}
.text-search {
    width: 450px !important; /* กำหนดความกว้าง */
    margin-bottom: 16px;

    /* กำหนด borderRadius สำหรับ fieldset */
    & .MuiOutlinedInput-root {
        & fieldset {
            border-radius: 30px; /* กำหนด borderRadius */
        }
    }

    /* กำหนดขนาดตัวอักษรใน input */
    & .MuiInputBase-input {
        font-size: 20px; /* กำหนด fontSize */
    }

    /* กำหนด borderBottom เมื่อ TextField ไม่ถูก focus */
    & .MuiInput-underline:before {
        border-bottom: 1px solid #BDBDBD; /* ใช้สี divider */
    };

     /* กำหนดระยะห่างด้านล่าง */
}

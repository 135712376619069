@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600&family=Smooch+Sans:wght@100;200;300;400&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.top-to-btm{
  position: relative;
}
.icon-position-backtotop{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}
.icon-style-backtotop{
  background-color: #E8630A;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  /* animation: movebtn 3s ease-in-out infinite; */
  /* transition: all .5s ease-in-out; */
}
.icon-style-backtotop:hover{
  animation: none;
  background: #fff;
  color: #E8630A;
  border: 2px solid #E8630A;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: black !important;  /* ใช้ !important เพื่อให้การตั้งค่ามีผล */
}

/* ขอบสีดำเมื่อโฟกัส */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: black !important;  /* ใช้ !important เพื่อให้การตั้งค่ามีผล */
}


/* label สีดำเมื่อโฟกัส */
.MuiInputLabel-root.Mui-focused {
  color: black !important; /* สีของ label เมื่อโฟกัส */
}
@keyframes movebtn {
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(20px);
  }
  50%{
    transform: translateY(0px);
  }
  75%{
    transform: translateY(-20px);
  }
  100%{
    transform: translateY(0px);
  }
}